'use client'

import Image from 'next/image'
import { Montserrat } from 'next/font/google'
import LoginForm from '../(marketing)/_components/login/login-form'

const montserrat = Montserrat({
  weight: '400',
  style: 'normal',
  subsets: ['latin'],
  display: 'swap',
})

const montserratBold = Montserrat({
  weight: '700',
  subsets: ['latin'],
  display: 'swap',
})

const LoginPage: React.FC = () => {
  return (
    <div
      className={`${montserrat.className} flex min-h-screen items-center justify-center bg-gray-100 px-4 py-12 sm:px-6 lg:px-8`}>
      <div className='w-full max-w-md space-y-8'>
        <div className='w-full max-w-md space-y-8 rounded-lg bg-white p-8 shadow-lg'>
          <div>
            <Image
              src={'/assets/images/logos/mascot2.png'}
              alt='HireTalk Logo'
              width={100}
              height={100}
              className='mx-auto'
            />
            <h2 className={`${montserratBold.className} mt-6 text-center text-3xl font-extrabold text-black`}>
              Welcome to HireTalk
            </h2>
            <p className='mt-2 text-center text-sm text-gray-600'>
              Log in to access your account. If you&apos;re new here, no worries – logging in will automatically create
              a new account for you.
            </p>
          </div>
          <LoginForm />
        </div>
      </div>
    </div>
  )
}

export default LoginPage
